import { createFeature, createReducer, on } from '@ngrx/store';
import { QueueActions } from './queue.actions';
import { DialerQueue } from 'src/app/shared/models';

export const queueFeatureKey = 'queue';

export interface QueueState {
  queue: DialerQueue | null;
  queues: DialerQueue[];
  loading: boolean;
  error: any;
}

export const initialState: QueueState = {
  queue: null,
  queues: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    QueueActions.loadQueues,
    (state): QueueState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueActions.loadQueuesSuccess,
    (state, { queues }): QueueState => ({
      ...state,
      queues: [...state.queues.filter((item) => !queues.find(x => x.id === item.id)), ...queues],
      loading: false,
      error: null,
    }),
  ),
  on(
    QueueActions.loadQueuesFailure,
    (state, { error }): QueueState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    QueueActions.loadQueue,
    (state): QueueState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueActions.loadQueueSuccess,
    (state, { queue }): QueueState => ({
      ...state,
      queues: [...state.queues.filter((item) => queue.id !== item.id), queue],
      queue: queue,
      loading: false,
      error: null,
    }),
  ),
  on(
    QueueActions.loadQueueFailure,
    (state, { error }): QueueState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    QueueActions.addQueue,
    (state): QueueState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueActions.addQueueSuccess,
    (state, { queue }): QueueState => ({
      ...state,

      queues: [...state.queues, queue],

      loading: false,

      error: null,
    }),
  ),
  on(
    QueueActions.addQueueFailure,
    (state, { error }): QueueState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    QueueActions.removeQueue,
    (state): QueueState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueActions.removeQueueSuccess,
    (state, { queueId }): QueueState => ({
      ...state,
      queues: state.queues.filter(
        (queue) => queue.id !== queueId,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    QueueActions.removeQueueFailure,
    (state, { error }): QueueState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    QueueActions.updateQueue,
    (state): QueueState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    QueueActions.updateQueueSuccess,
    (state, { queue }): QueueState => ({
      ...state,
      queues: state.queues.map((item) =>
        // item.id === queue.id ? queue : item
        item.id === queue.id ? { ...item, ...queue } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    QueueActions.updateQueueFailure,
    (state, { error }): QueueState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const queueFeature = createFeature({
  name: queueFeatureKey,
  reducer,
});
